@import "src/assets/scss/definitions";


.sidebar {
  $baseLeftPadding: 35px;

  position: fixed;
  left: 0;
  z-index: 10;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;

  &.open {
    max-width: 300px;
  }

  &.collapsed {
    max-width: 50px;
    padding-left: 0;
    $baseLeftPadding: 0px;

    .svg-inline--fa {
      margin: 0 !important;
    }

    h4 {
      margin: 0;
      background-color: lighten($mainBlack, 20);
    }
  }

  background-color: $mainBlack;
  padding-top: 0px;
  padding-right: 0;
  color: #ffffff;
  padding-left: 40px;

  h4 {
    color: #ffffff;
    font-size: 15px;
    margin-top: 30px;
    margin-bottom: 10px;
    padding-left: $baseLeftPadding;

  }

  .menu {
    list-style: none;
    padding-left: 0;
    margin-bottom:0;

    li {
      padding-left: $baseLeftPadding;
      height: 55px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      display: flex;
      align-items: center;

      &:first-child {
        border-top: 1px solid rgba(255, 255, 255, 0.2);
      }

      .svg-inline--fa {
        margin-right: 15px;
      }
    }

    .submenu {
      padding: 0;

      li {
        padding-left: $baseLeftPadding * 2;
        border: 0;
        background-color: rgba(#f8f8f9, 0.15);
      }

      a.active li, .btn-link.active li {
        padding-left: $baseLeftPadding * 2 - 5px;
      }
    }

    a, .btn-link {
      color: #ffffff;

      &.active, &:hover {
        color: $mainGold;
        text-decoration: none;
      }
    }

  }
}
