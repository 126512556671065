@import "src/assets/scss/definitions";

.order-table {
  .table-header {
    .btn {
      padding: 0;
    }
  }

  .table-row {
    grid-template-columns: 200px 100px 1fr 1fr 1fr 1fr 1fr 130px;
  }
}
