@import "./definitions";

.table {

  .table-header {
    .table-row .table-column {
      color: #7c8cac;
    }
  }

  .table-row {
    grid-gap: 0 20px;

    &:nth-child(even) {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &.cancelled {
      .table-column {
        color: #cbd9f5;
      }
    }

    .select-all {
      margin-left: 10px;
    }

    .selection-column {
      padding-left: 10px;
    }

    .name-column {
      .color-bubble {
        display: none;

        @include bootstrap-lg-and-below {
          display: block;
        }
      }
    }
  }
}

.table-edit-form {
  margin-top: 40px;

  label {
    display: block;
  }
}

.tk-table-listing {

  border-bottom: solid 1px #f0f4f8;

  &:first-child {
    border-top: solid 1px #f0f4f8;
  }

  padding: 40px 0;

  .tk-thumbbnail {

    a {

      .tk-image {

      }
    }
  }

  .tk-content {

    padding: 0 10px;

    .tk-ticket-number {
      @include sansFonts;
      margin-bottom: 10px;
    }

    .tk-ticket-name {

      h2 {
        margin-top: 0;
        margin-bottom: 10px;

        a {
          color: $mainBlack;
          text-decoration: none;
        }
      }
    }


  }

  .tk-ticket-stats {

    padding-top: 30px;
    @include sansFonts;
    color: $lightGrey;

    .tk-ticket-condition {

    }

    .tk-ticket-bid-count {

    }

    .tk-ticket-starting-bid {
      color: $mainBlack;
    }

    .tk-ticket-link {

      a {
        color: $lightGold;
      }
    }
  }
}
