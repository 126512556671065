@import "src/assets/scss/definitions";

.table.item-table {
  .table-header {
    .btn {
      padding: 0;
    }
  }

  .table-row {
    grid-template-columns: 25px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 130px;

    td {
      text-align: left;
    }
  }
}
