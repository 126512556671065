.log-entry-table {
  .table-row {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .message {
    width: 200px;
    hyphens: auto;
  }
}
