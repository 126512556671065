@import "../../../../assets/scss/colors";

.receipt-list {
  .receipt-row {
    display: grid;
    grid-template-columns: 150px 200px 150px 200px 200px 200px 1fr;

    &.header {
      font-weight: bold;
    }
  }
}
