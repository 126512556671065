
main {
  padding: 60px 40px 40px 60px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-content: center;
  overflow: visible;

  .content {

  }
}

.tooltip {
  pointer-events: none !important;
}
