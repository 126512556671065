.order-history-list {
  .history-row {
    display: grid;
    grid-template-columns: 150px 1fr;

    &.header {
      font-weight: bold;
    }
  }
}
