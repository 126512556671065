@import "../../../assets/scss/definitions";

.customers-table {
  .table-header {
    .btn {
      padding: 0;
    }
  }

  .table-row {
    grid-template-columns: 25px 1fr 1fr 1fr 1fr 130px;
  }
}

.bulk-customer-allowed-list-changer {
  form {

    align-items: center;

  }
}

.allowed-list-select-field {
  min-width: 155px;
  height: auto;
  padding: 0;

  .react-select__control {
    height: auto;
  }
}
