.assigned-lots-table {

  .table-header {
    .btn {
      padding: 0;
    }
  }

  &.status-in-planning {
    .table-row {
      grid-template-columns: 20px 50px 1fr 150px 1fr 200px 100px 130px;

      &.error-no-price {
        background: red;
      }
    }
  }

  &.status-finalized {
    .table-row {
      grid-template-columns: 20px 70px 70px 1fr 1fr 1fr 200px 100px 130px;
    }
  }

  &.status-concluded, &.status-live, &.status-archived {
    .table-row {
      grid-template-columns: 20px 70px 70px 1fr 1fr 1fr 1fr 200px 100px 130px;
    }
  }

  &.concluded {

  }
}
