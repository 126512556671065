.bid-info {
  font-size: 18px;
  line-height: 1.3;
  h3 {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .modal-body > div {
    margin-bottom: 10px
  }
}
