@import "../../../assets/scss/definitions";

.auctioneer-view {
  position: relative;
}


.auctioneer-view, .modal-content {
  .auctioneer-view-action-bar .assign-winning-bid, .btn {
    display: block;
    background-color: $mainGold;
    color: #ffffff !important;
    border: none;
    border-radius: 0;
    font-weight: bold;
    width: 100%;
    margin-bottom: 10px;

    &:hover {
      background-color: darken($mainGold, 20) !important;
    }
    &:focus {
      box-shadow: none;
    }
  }
}

.modal-title {
  @include serifFonts;

  span {
    margin-right: 10px;
  }
}

.auctioneer-view-content {
  display: flex;
  flex-direction: column;

  .auctioneer-view-content-tabs {
    flex: 1;
  }
}

.auctioneer-view-content-tabs {

  .nav-tabs {
    border-bottom: none;
  }

  a.nav-item {
    border: none;
    color: $mainBlack;

    &:first-child {
      padding-left: 0;
    }

    &.active, &:hover {
      color: $mainGold;
      font-weight: bold;
    }
  }

  .tab-pane {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 350px;
    overflow-y: scroll;
  }
}

.auctioneer-view-current-lot-content {

  .thumbnail {
    .tk-image {
      max-height: 300px;
    }
  }

  .lot-content {

    .lot-info {

      margin-bottom: 10px;

      .lot-number {
        font-weight: bold;
      }

      .lot-status {
        margin-left: 10px;
        font-weight: bold;
        color: $mainGold;

        &.last-call {
          color: $mainRed;
        }
      }

      .lot-category {

      }
    }

    .lot-title {

      h1 {
        @include serifFonts;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
        hyphens: auto;
      }
    }

    .lot-text {
      @include serifFonts;
      font-size: 15px;
    }
  }
}

.auctioneer-table {
  overflow-y: scroll;
  color: $mainBlack;

  .auctioneer-table-row {
    display: flex;
    padding: 5px;

    &:nth-child(even) {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &.header-row {
      font-weight: bold;
      font-size: 1.1em;
      margin-bottom: 20px;
      border-bottom: 1px solid $lightGrey;
    }

    &.current-lot {
      background-color: lighten($mainGold, 30);
    }

    &.highest-bid, &.live-lot {
      background-color: $mainGold;
      color: #ffffff;
    }

    > div {
      margin-right: 20px;
      min-width: 100px;
      flex: 1;

      &.bid-customer {
        min-width: 200px;
      }

      &.assign-winning-bid, &.lot-number {
        .btn-link, a {
          font-weight: bold;
          color: inherit;

          &:hover {
            color: inherit
          }
        }
      }

      .delete-bid {
        margin-left: 20px;
      }

      &.lot-title {
        hyphens: auto;
      }
    }
  }
}

.auctioneer-next-lots {

  h2 {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
    color: $lightGrey;
  }

  .auctioneer-next-lots-container {
    border-top: 1px solid $mainGold;
    border-bottom: 1px solid $mainGold;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;

    .auctioneer-lot-preview {
      flex: 1;
      a {
        display: flex;
        width: 100%;

        color: $mainBlack;

        &:hover {
          text-decoration: none;
        }

        .thumbnail {
          width: 100px;
          max-width: 30%;
        }

        .lot-preview-content {
          flex: 1;
          padding-left: 15px;

          .lot-number {
            font-weight: bold;
            line-height: 1;
            margin-bottom: 10px;
          }

          .lot-title {
            @include serifFonts;
            font-size: 20px;
            line-height: 1.1;
            font-weight: bold;
            hyphens: auto;
          }
        }
      }
    }
  }
}

.auctioneer-view-action-bar {
  background-color: $veryLightGold;
  margin-top: 40px;

}

.auctioneer-view-current-highest-bid {

  padding-top: 10px;
  padding-bottom: 20px;
  text-align: center;
  border-bottom: 1px solid $mainGold;

  &.source-online {
    color: $mainRed;
    border: 2px solid $mainRed;
  }

  .highest-bid-value {
    @include serifFonts;
    font-size: 32px;
    font-weight: bold;
  }

  .highest-bid-label {
    font-weight: bold;
  }

  .highest-bid-info {

    .highest-bid-customer-id, .highest-bid-tel, .highest-bid-limited-at, .highest-bid-source {
      padding: 0 5px;
      white-space: nowrap;
      display: block;
      font-size: 14px;
    }

  }

  .assign-winning-bid {
    margin-top: 20px;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
  }

  .close-lot-button {
    background-color: transparent;
    color: $mainBlack !important;
    border: 2px solid $mainBlack !important;
    padding: 0.3rem 0.75rem;

    &:hover {
      background-color: $lightGrey !important;
    }
  }
}

.auctioneer-view-bid-submit-form {
  padding-top: 20px;
  border-bottom: 1px solid $mainGold;

  form {

    .is-limit-bid {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      > label {
        font-size: 15px;
        font-weight: 400;
        padding-left: 0 !important;
      }

      > div {
        flex: 1;
      }

      input[type=checkbox] {
        height: 20px;
        width: 50px;
      }

      label {
        padding-left: 15px;
      }
    }

  }
}

.auctioneer-view-current-lot-statistics {

  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid $mainGold;
  color: $lightGrey;

  .price-label {

  }

  .price {
    margin-left: 10px;
  }
}

.auctioneer-view-action-buttons {
  padding-top: 20px;

  .last-call-button.last-call-active, .resume-button {
    background-color: $mainRed;

    &:hover {
      background-color: darken($mainRed, 20) !important;
    }
  }

  .conclude-button {
    border: 10px solid $mainRed;
  }
}

.tk-loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255, 0.5);
  left: 0;
  top: 0;
  z-index: 999999999;
}

.no-auction.jumbotron {
  text-align: center;
}
