@import "../../../../assets/scss/definitions";

.lot-table {

  .table-row {
    grid-template-columns: 25px 1fr 1fr 1fr 1fr 130px;

    &.cancelled {
      .table-column {
        color: #cbd9f5;
      }
    }

    .name-column {
      .color-bubble {
        display: none;

        @include bootstrap-lg-and-below {
          display: block;
        }
      }
    }
  }
}

.lot-edit-form {
  margin-top: 40px;

  label {
    display: block;
  }
}
