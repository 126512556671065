.pricing-information-display {
  font-size: 14px;
  .prices {
    display: flex;

    .real-price.discounted {
      margin-left: 10px;
      font-size: 12px;
      text-decoration: line-through;
    }
  }
  .discounts {
    .discount {
      font-size: 10px;
      color: red;
    }
  }
}
